<div class="row" *ngIf="this.Role == userRole">
  <div class="column-left mobile">
    <p class="question">What time did you wake up this morning?</p>
    <p class="user-inputs">
      {{
        entryForDay?.wake_up_date_time
          ? (entryForDay.wake_up_date_time | date : "yyyy-MM-dd h:mm a")
          : ""
      }}
      <!-- {{entryForDay?.wake_up_time | strToTime | date:'HH:mm a' ?? 'no'}} -->
    </p>
    <p class="question">What time did you rise from bed this morning?</p>
    <p class="user-inputs">
      {{
        entryForDay?.rise_from_bed_date_time
          ? (entryForDay.rise_from_bed_date_time | date : "yyyy-MM-dd h:mm a")
          : ""
      }}
    </p>

    <p class="question">What time did you go to bed last night?</p>
    <p class="user-inputs">
      <!--TODO: CHECK FOR AM/PM-->
      {{
        entryForDay?.go_to_bed_date_time
          ? (entryForDay.go_to_bed_date_time | date : "yyyy-MM-dd h:mm a")
          : ""
      }}
    </p>
    <p class="question">What time did you turn the light out?</p>
    <p class="user-inputs">
      {{
        entryForDay?.lights_off_date_time
          ? (entryForDay.lights_off_date_time | date : "yyyy-MM-dd h:mm a")
          : ""
      }}
    </p>

    <p class="question">How long did it take you to fall asleep?</p>
    <p class="user-inputs">
      {{
        entryForDay?.duration_falling_sleep
          ? (entryForDay.duration_falling_sleep | formatTime)
          : ""
      }}
    </p>
    <!-- <p class="question">Number of awakenings throughout the night</p>
    <p class="user-inputs">
      {{ entryForDay?.number_awakening }}
    </p>
    <p class="question">Time Awake during Awakenings</p>
    <p class="user-inputs">
      {{
        entryForDay?.minutes_awake
          ? (entryForDay.minutes_awake | formatTime)
          : ""
      }}
    </p> -->
  </div>
  <div class="column-right mobile">
    <p class="question">Number of awakenings throughout the night</p>
    <p class="user-inputs">
      {{ entryForDay?.number_awakening }}
    </p>
    <p class="question">Time Awake during Awakenings</p>
    <p class="user-inputs">
      {{
        entryForDay?.minutes_awake
          ? (entryForDay.minutes_awake | formatTime)
          : ""
      }}
    </p>
    <!-- <p class="question">List of stimulants you took</p>
    <p class="user-inputs">
      {{ entryForDay?.list_stimulants }}
    </p>
    <p class="question">List any sleep medications</p>
    <p class="user-inputs">
      {{ entryForDay?.list_sleep_medications }}
    </p> -->
    <p class="question">How well rested are you?</p>
    <p class="user-inputs">
      {{ entryForDay?.rate_rest }}{{ entryForDay?.rate_rest ? "/5" : "" }}
    </p>
    <p class="question">Was your sleep of good quality?</p>
    <p class="user-inputs">
      {{ entryForDay?.rate_sleep_quality
      }}{{ entryForDay?.rate_sleep_quality ? "/5" : "" }}
    </p>
    <div class="form-group">
      <button
        id="delete-entry"
        class="btn btn-primary field mx-auto col-6"
        (click)="removeEntry(entryForDay.id)"
      >
        Delete Entry
      </button>
    </div>
  </div>
</div>
