<div class="container p-4 container-custom outer">
  <h1
    style="
      color: #012233;
      margin-bottom: 10px !important;
      font-size: 28px !important;
    "
  >
    <b>{{ "W2_TIME_BED_CALCULATOR" | translate }}</b>
  </h1>
  <h4 style="color: #002e46; font-size: 18px">
    <br />
    <!-- <p>
      {{ "W2_VIDEO_HOW_CALCULATE_SLEEP" | translate }}
    </p> -->

    <div class="speech-bubble-container">
      <video
        class="person-video"
        autoplay
        controls
        muted
        (ended)="onVideoEnded()"
        [src]="videoSrc"
        #videoPlayer
      ></video>
    </div>
    <br />
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3"
        (click)="onNextClick()"
        [disabled]="!isVideoEnded"
      >
        {{ "NEXT" | translate }}
      </button>
    </div>
  </h4>
</div>
