import { Component, Input, OnInit } from '@angular/core';
import { Journal } from '@app/_models/journal';
import { Role } from '@app/_models';
import { AccountService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';
@Component({
  templateUrl: 'diaryjournal.component.html',
  selector: 'diaryjournal',
  styleUrls: ['diaryjournal.style.scss'],
})
export class DiaryJournalComponent implements OnInit {
  userRole = Role.User;
  adminRole = Role.Admin;
  Role: Role;
  @Input() entryForDay: Journal;

  dummy = {
    entry_date: '2020-01-01',
    wake_up_time: '06:00',
    rise_from_bed: '06:00',
    go_to_bed: '23:35',
    lights_off_time: '23:34',
    duration_falling_asleep: '10',
    number_awakening: '4',
    minutes_awake: '50',
    // list_stimulants: '1 Coffee',
    // list_sleep_medications: 'n/a',
    rate_rest: '1',
    rate_sleep_quality: '1',
  };

  constructor(
    private accountService: AccountService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.Role = this.accountService.Role;
  }

  onDeleteJournal(journalId) {
    this.accountService
      .deleteJournalDetailsById(journalId)
      .pipe(first())
      .subscribe({
        next: (data) => {
          window.location.reload();
        },
        error: (error) => {
          this.alertService.error(error);
          // this.loading = false;
        },
      });
  }
  removeEntry(journalId: Number) {
    if (confirm('Are you sure to delete journal entry?')) {
      this.onDeleteJournal(journalId);
    }
  }
}
