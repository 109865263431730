<h3 class="card-header">
  <div class="row center">
    <p id="header-text" class="d-inline-block text-left">
      {{ "JOURNAL" | translate }}
    </p>
  </div>
  <div class="row center">
    <p id="header-subtext" class="d-inline-block text-left mb-0">
      {{ "JOURNAL_FILL_OUT" | translate }}
    </p>
  </div>
</h3>
<div class="card-body">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- <div *ngIf="page == 1"> -->
    <div class="form-group remove-underline">
      <mat-form-field appearance="outline" style="width: 40%">
        <mat-label>{{ "JOURNAL_PICK_DATA" | translate }}</mat-label>

        <input
          style="width: 100%"
          name="entry_date"
          matInput
          formControlName="entry_date"
          [matDatepicker]="picker2"
          placeholder="Pick a Date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
      <div class="text-danger">
        <div *ngIf="f.entry_date?.invalid && f.entry_date?.touched">
          {{ "JOURNAL_VALID_DATE" | translate }}
        </div>
        <div *ngIf="f.entry_date.errors?.dateShouldNotBeGreaterThanTodayError">
          {{
            f.entry_date.errors?.dateShouldNotBeGreaterThanTodayError
              | translate
          }}
        </div>
      </div>
    </div>

    <div class="form-group remove-underline">
      <mat-form-field appearance="outline">
        <mat-label>{{ "JOURNAL_WAKEUP_TIME" | translate }}</mat-label>

        <input
          matInput
          formControlName="wake_up_date_time"
          id="timepicker-example-2"
          placeholder="Please select time..."
          (mousedown)="openWakeUpTimePicker($event)"
          readonly
        />
        <mat-icon matSuffix (mousedown)="openWakeUpTimePicker($event)"
          >access_time</mat-icon
        >
      </mat-form-field>
      <div class="text-danger">
        <div
          *ngIf="f.wake_up_date_time?.invalid && f.wake_up_date_time?.touched"
        >
          {{ "JOURNAL_VALID_TIME" | translate }}
        </div>

        <div *ngIf="f.wake_up_date_time.errors?.wakeUpTimeShouldEarlyNowError">
          {{
            f.wake_up_date_time.errors?.wakeUpTimeShouldEarlyNowError
              | translate
          }}
        </div>
        <div *ngIf="f.wake_up_date_time.errors?.wakeUpTimeCompEntryError">
          <span
            [innerHTML]="
              f.wake_up_date_time.errors?.wakeUpTimeCompEntryError
                | translate
                  : {
                      entryDate: f.wake_up_date_time.errors?.entryDate,
                      nextEntryDate: f.wake_up_date_time.errors?.nextEntryDate
                    }
            "
          ></span>
        </div>
        <div *ngIf="f.wake_up_date_time.errors?.wakeUpTimeDataError">
          {{ f.wake_up_date_time.errors?.wakeUpTimeDataError | translate }}
        </div>
        <div *ngIf="f.wake_up_date_time.errors?.sleepTimeNegativeError">
          {{ f.wake_up_date_time.errors?.sleepTimeNegativeError | translate }}
        </div>
        <div *ngIf="f.wake_up_date_time.errors?.sleepTimeCheckError">
          {{ f.wake_up_date_time.errors?.sleepTimeCheckError | translate }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "JOURNAL_RISE_TIME" | translate }}</mat-label>

        <input
          matInput
          formControlName="rise_from_bed_date_time"
          id="timepicker-example-2"
          placeholder="Please select time..."
          (mousedown)="openRiseFromBedTimePicker($event)"
          readonly
        />
        <mat-icon matSuffix (mousedown)="openRiseFromBedTimePicker($event)"
          >access_time</mat-icon
        >
      </mat-form-field>
      <div class="text-danger">
        <div
          *ngIf="
            f.rise_from_bed_date_time?.invalid &&
            f.rise_from_bed_date_time?.touched
          "
        >
          {{ "JOURNAL_VALID_TIME" | translate }}
        </div>
        <div
          *ngIf="f.rise_from_bed_date_time.errors?.riseTimeShouldEarlyNowError"
        >
          {{
            f.rise_from_bed_date_time.errors?.riseTimeShouldEarlyNowError
              | translate
          }}
        </div>
        <div *ngIf="f.rise_from_bed_date_time.errors?.riseTimeCompEntryError">
          <span
            [innerHTML]="
              f.rise_from_bed_date_time.errors?.riseTimeCompEntryError
                | translate
                  : {
                      entryDate: f.rise_from_bed_date_time.errors?.entryDate,
                      nextEntryDate:
                        f.rise_from_bed_date_time.errors?.nextEntryDate
                    }
            "
          ></span>
        </div>
        <div *ngIf="f.rise_from_bed_date_time.errors?.riseFromBedDataError">
          {{
            f.rise_from_bed_date_time.errors?.riseFromBedDataError | translate
          }}
        </div>
        <div *ngIf="f.rise_from_bed_date_time.errors?.bedTimeError">
          {{ f.rise_from_bed_date_time.errors?.bedTimeError | translate }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "JOURNAL_BED_TIME" | translate }}</mat-label>

        <input
          matInput
          formControlName="go_to_bed_date_time"
          id="timepicker-example-2"
          placeholder="Please select time..."
          (mousedown)="openGoToBedTimePicker($event)"
          readonly
        />
        <mat-icon matSuffix (mousedown)="openGoToBedTimePicker($event)"
          >access_time</mat-icon
        >
      </mat-form-field>
      <div class="text-danger">
        <div
          *ngIf="
            f.go_to_bed_date_time?.invalid && f.go_to_bed_date_time?.touched
          "
        >
          {{ "JOURNAL_VALID_TIME" | translate }}
        </div>
        <div *ngIf="f.go_to_bed_date_time.errors?.bedTimeShouldEarlyNowError">
          {{
            f.go_to_bed_date_time.errors?.bedTimeShouldEarlyNowError | translate
          }}
        </div>

        <div *ngIf="f.go_to_bed_date_time.errors?.bedTimeCompEntryError">
          <span
            [innerHTML]="
              f.go_to_bed_date_time.errors?.bedTimeCompEntryError
                | translate
                  : {
                      entryDate: f.go_to_bed_date_time.errors?.entryDate,
                      nextEntryDate: f.go_to_bed_date_time.errors?.nextEntryDate
                    }
            "
          ></span>
        </div>
        <div *ngIf="f.go_to_bed_date_time.errors?.error">
          <span
            [innerHTML]="
              f.go_to_bed_date_time.errors?.error
                | translate
                  : {
                      riseFromBedTime:
                        f.go_to_bed_date_time.errors?.riseFromBedTime
                    }
            "
          ></span>
        </div>
        <div *ngIf="f.go_to_bed_date_time.errors?.goToBedTimeDataError">
          {{ f.go_to_bed_date_time.errors?.goToBedTimeDataError | translate }}
        </div>
        <div *ngIf="f.go_to_bed_date_time.errors?.bedTimeError">
          {{ f.go_to_bed_date_time.errors?.bedTimeError | translate }}
        </div>
        <div
          *ngIf="
            f.go_to_bed_date_time.errors?.checkBedTimeGreaterThanYesterdayError
          "
        >
          {{
            f.go_to_bed_date_time.errors?.checkBedTimeGreaterThanYesterdayError
              | translate
          }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "JOURNAL_LIGHT_OFF_TIME" | translate }}</mat-label>

        <input
          matInput
          formControlName="lights_off_date_time"
          id="timepicker-example-2"
          placeholder="Please select time..."
          (mousedown)="openLightsOffTimePicker($event)"
          readonly
        />
        <mat-icon matSuffix (mousedown)="openLightsOffTimePicker($event)"
          >access_time</mat-icon
        >
      </mat-form-field>
      <div class="text-danger">
        <div
          *ngIf="
            f.lights_off_date_time?.invalid && f.lights_off_date_time?.touched
          "
        >
          {{ "JOURNAL_VALID_TIME" | translate }}
        </div>

        <div
          *ngIf="
            f.lights_off_date_time.errors?.lightsOffTimeShouldEarlyNowError
          "
        >
          {{
            f.lights_off_date_time.errors?.lightsOffTimeShouldEarlyNowError
              | translate
          }}
        </div>
        <div *ngIf="f.lights_off_date_time.errors?.lightsOffTimeCompEntryError">
          <span
            [innerHTML]="
              f.lights_off_date_time.errors?.lightsOffTimeCompEntryError
                | translate
                  : {
                      entryDate: f.lights_off_date_time.errors?.entryDate,
                      nextEntryDate:
                        f.lights_off_date_time.errors?.nextEntryDate
                    }
            "
          ></span>
        </div>
        <div *ngIf="f.lights_off_date_time.errors?.lightsOffTimeDataError">
          {{
            f.lights_off_date_time.errors?.lightsOffTimeDataError | translate
          }}
        </div>
        <div *ngIf="f.lights_off_date_time.errors?.sleepTimeNegativeError">
          {{
            f.lights_off_date_time.errors?.sleepTimeNegativeError | translate
          }}
        </div>
        <div *ngIf="f.lights_off_date_time.errors?.sleepTimeCheckError">
          {{ f.lights_off_date_time.errors?.sleepTimeCheckError | translate }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "JOURNAL_FALL_ASLEEP_TIME" | translate }}</mat-label>

        <input
          matInput
          formControlName="duration_falling_sleep"
          id="timepicker-example-2"
          placeholder="Please select time..."
          (mousedown)="openDurationTimePicker($event)"
          readonly
        />
        <mat-icon matSuffix (mousedown)="openDurationTimePicker($event)"
          >access_time</mat-icon
        >
      </mat-form-field>
      <div class="text-danger">
        <div
          *ngIf="
            f.duration_falling_sleep?.invalid &&
            f.duration_falling_sleep?.touched
          "
        >
          {{ "JOURNAL_SELECT_HOUR_MINUTE" | translate }}
        </div>
        <div *ngIf="f.duration_falling_sleep.errors?.sleepTimeCheckError">
          {{ f.duration_falling_sleep.errors?.sleepTimeCheckError | translate }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label> {{ "JOURNAL_NUMBER_AWAKE" | translate }}</mat-label>

        <input
          matInput
          formControlName="number_awakening"
          type="number"
          min="0"
        />
        <mat-icon matSuffix> mode_standby</mat-icon>
      </mat-form-field>
      <div class="text-danger">
        <div *ngIf="f.number_awakening?.invalid && f.number_awakening?.touched">
          {{ "JOURNAL_VALID_NUMBER" | translate }}
        </div>
        <div *ngIf="f.number_awakening.errors?.valueShouldNotBeEmptyError">
          {{
            f.number_awakening.errors?.valueShouldNotBeEmptyError | translate
          }}
        </div>

        <div
          *ngIf="f.number_awakening.errors?.min && f.number_awakening.touched"
        >
          {{ "JOURNAL_NOT_NEGATIVE" | translate }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "JOURNAL_TIME_AWAKE" | translate }}</mat-label>

        <input
          matInput
          formControlName="minutes_awake"
          id="timepicker-example-2"
          placeholder="Please select time..."
          (mousedown)="openMinutesAwakePicker($event)"
          readonly
        />
        <mat-icon matSuffix (mousedown)="openMinutesAwakePicker($event)"
          >access_time</mat-icon
        >
      </mat-form-field>
      <div class="text-danger">
        <div
          *ngIf="
            f.minutes_awake.errors?.invalid && f.minutes_awake.errors?.touched
          "
        >
          {{ "JOURNAL_SELECT_HOUR_MINUTE" | translate }}
        </div>
        <div *ngIf="f.minutes_awake.errors?.valueShouldNotBeEmptyError">
          {{ f.minutes_awake.errors?.valueShouldNotBeEmptyError | translate }}
        </div>
        <div *ngIf="f.minutes_awake.errors?.sleepTimeCheckError">
          {{ f.minutes_awake.errors?.sleepTimeCheckError | translate }}
        </div>
      </div>
    </div>

    <div
      class="form-group add-box mb-0"
      [ngClass]="{
        'error-border':
          form.get('rate_rest')?.invalid && form.get('rate_rest')?.touched
      }"
    >
      <label style="width: 100%; text-align: left" for="restQuality"
        >{{ "JOURNAL_SLEEP_QUALITY_TODAY" | translate }}
        <br />
        <br />
        <section class="section1 ratings">
          <div>
            <input
              type="radio"
              formControlName="rate_rest"
              id="control_01"
              name="rate_rest"
              value="1"
              checked
              class="input1"
            />
            <label for="control_01" class="label1 control_01">
              <mat-icon> sentiment_very_dissatisfied</mat-icon>

              <p>{{ "JOURNAL_NOT_AT_ALL" | translate }}</p>
            </label>
          </div>
          <div>
            <input
              type="radio"
              formControlName="rate_rest"
              id="control_02"
              name="rate_rest"
              value="2"
              class="input1"
            />
            <label for="control_02" class="label1 control_02">
              <mat-icon> sentiment_dissatisfied</mat-icon>
              <p>{{ "JOURNAL_SLIGHTLY" | translate }}</p>
            </label>
          </div>
          <div>
            <input
              type="radio"
              formControlName="rate_rest"
              id="control_03"
              name="rate_rest"
              value="3"
              class="input1"
            />
            <label for="control_03" class="label1 control_03">
              <mat-icon> sentiment_neutral</mat-icon>
              <p>{{ "JOURNAL_MODERATELY" | translate }}</p>
            </label>
          </div>
          <div>
            <input
              type="radio"
              formControlName="rate_rest"
              id="control_04"
              name="rate_rest"
              value="4"
              class="input1"
            />
            <label for="control_04" class="label1 control_04">
              <mat-icon> sentiment_satisfied</mat-icon>
              <p>{{ "JOURNAL_QUITE_A_LOT" | translate }}</p>
            </label>
          </div>
          <div>
            <input
              type="radio"
              formControlName="rate_rest"
              id="control_05"
              name="rate_rest"
              value="5"
              class="input1"
            />

            <label for="control_05" class="label1 control_05">
              <mat-icon> sentiment_very_satisfied</mat-icon>
              <p>{{ "JOURNAL_VERY_WELL" | translate }}</p>
            </label>
          </div>
        </section>
      </label>

      <div
        class="text-danger"
        *ngIf="f.rate_rest?.invalid && f.rate_rest?.touched"
      >
        {{ "JOURNAL_REST_FEEL" | translate }}
      </div>

      <!-- <div *ngIf="submitted && f.rate_rest.errors" class="text-danger">
        This field id required
      </div> -->
    </div>

    <div
      class="form-group add-box mb-0 mt-4"
      [ngClass]="{
        'error-border':
          f.rate_sleep_quality?.invalid && f.rate_sleep_quality?.touched
      }"
    >
      <label style="width: 100%; text-align: left" for="sleepQuality"
        >{{ "JOURNAL_SLEEP_QUALITY" | translate }}
        <br />
        <br />
        <section class="section2 ratings">
          <div>
            <input
              formControlName="rate_sleep_quality"
              type="radio"
              id="control_06"
              name="rate_sleep_quality"
              value="1"
              checked
              class="input2"
            />
            <label for="control_06" class="label2 control_06">
              <mat-icon> sentiment_very_dissatisfied</mat-icon>

              <p>{{ "JOURNAL_NOT_AT_ALL" | translate }}</p>
            </label>
          </div>
          <div>
            <input
              formControlName="rate_sleep_quality"
              type="radio"
              id="control_07"
              name="rate_sleep_quality"
              value="2"
              class="input2"
            />
            <label for="control_07" class="label2 control_07">
              <mat-icon> sentiment_dissatisfied</mat-icon>
              <p>{{ "JOURNAL_SLIGHTLY" | translate }}</p>
            </label>
          </div>
          <div>
            <input
              formControlName="rate_sleep_quality"
              type="radio"
              id="control_08"
              name="rate_sleep_quality"
              value="3"
              class="input2"
            />
            <label for="control_08" class="label2 control_08">
              <mat-icon> sentiment_neutral</mat-icon>
              <p>{{ "JOURNAL_MODERATELY" | translate }}</p>
            </label>
          </div>
          <div>
            <input
              formControlName="rate_sleep_quality"
              type="radio"
              id="control_09"
              name="rate_sleep_quality"
              value="4"
              class="input2"
            />
            <label for="control_09" class="label2 control_09">
              <mat-icon> sentiment_satisfied</mat-icon>
              <p>{{ "JOURNAL_QUITE_A_LOT" | translate }}</p>
            </label>
          </div>
          <div>
            <input
              formControlName="rate_sleep_quality"
              type="radio"
              id="control_10"
              name="rate_sleep_quality"
              value="5"
              class="input2"
            />
            <label for="control_10" class="label2 control_10">
              <mat-icon> sentiment_very_satisfied</mat-icon>
              <p>{{ "JOURNAL_VERY_WELL" | translate }}</p>
            </label>
          </div>
        </section>
      </label>

      <div
        class="text-danger"
        *ngIf="f.rate_sleep_quality?.invalid && f.rate_sleep_quality?.touched"
      >
        {{ "JOURNAL_QUALITY_SLEEP" | translate }}
      </div>

      <!-- <div *ngIf="submitted && f.rate_sleep_quality.errors" class="text-danger">
        This field id required
      </div> -->
    </div>

    <div *ngIf="formError" class="mt-4 text-center text-danger">
      {{ "JOURNAL_GLOBAL_ERROR" | translate }}
    </div>

    <!-- <div *ngIf="formError" class="mt-4 text-center text-danger">
      You have some errors in the form, Please check it again
    </div> -->

    <div class="buttons form-group mx-auto flex-column mt-4">
      <!-- <button
          class="field btn btn-secondary col-3 min-size"
          (click)="pageOne()"
        >
          {{ "JOURNAL_PREVIOUS" | translate }}</button
        ><br /> -->
      <button class="field btn btn-primary col-3 min-size">
        {{ "SUBMIT" | translate }}
      </button>
    </div>
    <!-- </div> -->
  </form>
</div>
