import { AbstractControl, ValidatorFn } from '@angular/forms';

export default class Validation {
  static dateShouldBeLessThan(
    wakeUpTime: string,
    goToBedTime: string,
    dailySleepTime: string
  ): ValidatorFn {
    return (controls: AbstractControl) => {
      console.log('Validation triggered');
      const goToBedControl = controls.get(goToBedTime);
      const wakeUpControl = controls.get(wakeUpTime);
      const dailySleepTimeControl = controls.get(dailySleepTime);

      if (
        !goToBedControl?.value ||
        !wakeUpControl?.value ||
        !dailySleepTimeControl?.value
      ) {
        return null; // Skip validation if either value is empty
      }

      const goToBedValue = goToBedControl.value;
      const wakeUpValue = wakeUpControl.value;
      const dailySleepTimeValue = dailySleepTimeControl.value;

      const goToBedMinutes = this.parseTimeToMinutes(goToBedValue);
      const wakeUpMinutes = this.parseTimeToMinutes(wakeUpValue);
      const dailySleepMinutes =
        this.parseSleepDurationToMinutes(dailySleepTimeValue);

      if (
        goToBedMinutes === null ||
        wakeUpMinutes === null ||
        dailySleepMinutes === null
      ) {
        return null;
      }

      let sleepDuration = wakeUpMinutes - goToBedMinutes;
      if (sleepDuration < 0) {
        sleepDuration += 24 * 60;
      }

      if (
        (dailySleepMinutes <= 6 * 60 && sleepDuration > 6 * 60) ||
        (dailySleepMinutes > 6 * 60 && sleepDuration > dailySleepMinutes)
      ) {
        wakeUpControl.setErrors({
          desiredDadilySleepTimeChkError: 'W2_BED_TIME_EARLIER',
          dailySleepTime: dailySleepTimeValue,
        });
        goToBedControl.setErrors({
          desiredDadilySleepTimeChkError: 'W2_BED_TIME_EARLIER',
          dailySleepTime: dailySleepTimeValue,
        });
      } else {
        // Clear error if times are valid
        if (wakeUpControl.hasError('desiredDadilySleepTimeChkError')) {
          wakeUpControl.setErrors(null);
        }
        if (goToBedControl.hasError('desiredDadilySleepTimeChkError')) {
          goToBedControl.setErrors(null);
        }
      }

      return null;
    };
  }

  private static parseTimeToMinutes(time: string): number | null {
    const match = time.match(/^(\d{1,2}):(\d{2}) (AM|PM)$/i);
    if (!match) return null;

    let hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const period = match[3].toUpperCase();

    if (hours === 12) hours = 0; // 12AM = 0小时
    if (period === 'PM') hours += 12;

    return hours * 60 + minutes;
  }

  private static parseSleepDurationToMinutes(duration: string): number | null {
    const match = duration.match(/^(\d+)h (\d+)min$/i);
    if (!match) return null;

    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);

    return hours * 60 + minutes;
  }
}
