import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.less'],
})
export class TestComponent implements OnInit {
  constructor(private formBuilder: FormBuilder) {}
  form;
  ngOnInit() {
    this.form = this.formBuilder.group({
      entry_date: ['', Validators.required],
      wake_up_date_time: ['', Validators.required],
      rise_from_bed_date_time: ['', Validators.required],
      go_to_bed_date_time: ['', Validators.required],
      lights_off_date_time: ['', Validators.required],
      duration_falling_sleep: [''],
      number_awakening: ['', Validators.required],
      minutes_awake: [''],
      // list_stimulants: ['', Validators.required],
      // list_sleep_medications: ['', Validators.required],
      rate_rest: ['', Validators.required],
      rate_sleep_quality: ['', Validators.required],
      //user_id:1
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    debugger;
  }
}
